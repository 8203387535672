const { useState, useEffect, useCallback } = require('react');

const useCustomSelect = ({ $customSelectRef, defaultValue, defaultLabel }) => {
    const [state, setState] = useState({
        selectedValue: defaultValue || null,
        selectedLabel: defaultLabel || null,
        expanded: false,
    });
    const handleSetSelected = ({ value, label }) => {
        setState(prevState => ({
            ...prevState,
            selectedValue: value,
            selectedLabel: label,
        }));
    };
    const handleToggleExpanded = () => {
        setState(prevState => {
            return {
                ...prevState,
                expanded: !prevState.expanded,
            };
        });
    };

    const handleBodyClick = useCallback(
        e => {
            if ($customSelectRef?.current) {
                if (!$customSelectRef?.current?.contains(e.target)) {
                    setState(prevState => ({
                        ...prevState,
                        expanded: false,
                    }));
                    return;
                }
                handleToggleExpanded();
            }
        },
        [$customSelectRef, setState]
    );

    useEffect(() => {
        document.addEventListener('click', handleBodyClick);
        return () => {
            document.removeEventListener('click', handleBodyClick);
        };
    }, []);

    return {
        selectedValue: state.selectedValue,
        selectedLabel: state.selectedLabel,
        expanded: state.expanded,
        handleSetSelected,
        handleToggleExpanded,
    };
};

export default useCustomSelect;
