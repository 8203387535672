import PropTypes from 'prop-types';
import useCustomSelect from 'components/atoms/hooks/useCustomSelect';
import { CaretDownIcon, ChevronLeftIcon, ChevronRightIcon } from 'components/atoms/Icons';
import { BodyText, H2 } from 'components/atoms/Typography';
import Link from 'components/atoms/Link';
import { useContext, useEffect, useRef } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import NextImage from 'next/image';
import breakpoints from 'styles/breakpoints';
import RevealElement from 'components/atoms/RevealElement';
import { Section } from 'components/atoms/Container';
import SiteContext from 'context/SiteContext';
import ScrollingCarousel from 'components/molecules/ScrollingCarousel';
import { roundToTwo } from 'helpers';
import { storyblokEditable } from '@storyblok/react';
import { logInfo } from 'helpers/logging';
import { APPS } from 'helpers/constants/general';
import {
    transformProductsToElevarImpressions,
    transformToElevarUserProperties,
} from 'helpers/elevar';

const Organism = styled(Section)`
    .trigger {
    }
    .trigger__iconWrapper {
        background-color: rgb(var(--heading-color));
        color: rgb(var(--bg-light));
    }
    .options {
        background-color: rgb(var(--bg-light));
    }
    .option {
        &:hover {
            background-color: rgba(var(--bg-dark), 0.04);
        }
    }
`;

const FeaturedCollectionsProducts = ({ blok }) => {
    const {
        store: {
            customer,
            customerLoggedIn,
            tradeDiscountValue,
            checkout: { currencyCode },
        },
    } = useContext(SiteContext);

    const data = useMemo(() => {
        const { _uid, heading, allProductsLabel, collections, products } = { ...blok };
        return {
            _uid,
            heading,
            allProductsLabel,
            collections,
            products,
            list: `Homepage - ${heading ?? 'Shop your favorite'}`,
        };
    }, [blok]);

    const $selectRef = useRef(null);

    const { selectedValue, selectedLabel, expanded, handleSetSelected } = useCustomSelect({
        $customSelectRef: $selectRef,
        defaultValue: data?.allProductsLabel ? 'all' : data?.collections?.[0]?.handle,
        defaultLabel: data?.allProductsLabel || data?.collections?.[0]?.title,
    });

    useEffect(() => {
        /* Delayed to ensure 'dl_user_data' is fired first */
        setTimeout(() => {
            /* Wait for checkout to be initialized */
            if (!currencyCode || (customerLoggedIn && !customer)) {
                return;
            }

            /** Collection page product impressions **/
            window.ElevarDataLayer = window.ElevarDataLayer ?? [];
            const viewItemListData = {
                event: 'dl_view_item_list',
                user_properties: {
                    ...transformToElevarUserProperties({ customer, customerLoggedIn }),
                },
                ecommerce: {
                    currencyCode: 'USD',
                    impressions: transformProductsToElevarImpressions({
                        products: (data.products?.[selectedValue] ?? []).slice(0, 10),
                        list: data.list,
                        startIndex: 1,
                        tradeDiscountValue,
                    }),
                },
            };
            window.ElevarDataLayer.push(viewItemListData);
            logInfo('Fire Elevar dl_view_item_list', APPS.FRONTEND, viewItemListData);
        }, 100);
    }, [currencyCode, data.products]);

    return (
        <Organism className="lg:py-7 overflow-hidden my-16 lg:my-24" {...storyblokEditable(blok)}>
            <div className="container flex flex-wrap gap-x-3 mb-6 lg:mb-5">
                {data.heading && (
                    <RevealElement>
                        <H2 className="mb-2" id={`label-${data._uid}`} lineHeight="1">
                            {data.heading}
                        </H2>
                    </RevealElement>
                )}
                <div ref={$selectRef} className="relative mb-2">
                    <RevealElement delay={0.1}>
                        <button
                            className="trigger flex flex-wrap items-center justify-between border-b-2 border-current"
                            role="combobox"
                            aria-haspopup="listbox"
                            aria-labelledby={`label-${data._uid} selected-collection-${data._uid}`}
                            aria-expanded={expanded}
                            aria-controls={`list-${data._uid}`}
                        >
                            <H2
                                className="italic text-left text-ellipsis whitespace-pre overflow-hidden w-60 pl-1 pr-6"
                                as="span"
                                lineHeight="1"
                                id={`selected-${data._uid}`}
                            >
                                {selectedLabel}
                            </H2>
                            <span
                                className="trigger__iconWrapper h-6 w-6 flex items-center justify-center"
                                aria-hidden="true"
                            >
                                <CaretDownIcon />
                            </span>
                        </button>
                    </RevealElement>
                    <input type="hidden" name="selected-collection" value="" />
                    <ul
                        className={`options absolute z-20 top-full left-0 w-full border-b-2 border-current shadow-2xl flex flex-col ${
                            expanded ? '' : 'hidden'
                        }`}
                        id={`list-${data._uid}`}
                        role="listbox"
                        aria-labelledby={`label-${data._uid}`}
                    >
                        {data?.allProductsLabel && (
                            <li role="option" aria-selected={selectedValue === 'all'}>
                                <button
                                    className="option px-4 py-1.5 w-full text-left"
                                    onClick={() =>
                                        handleSetSelected({
                                            value: 'all',
                                            label: data.allProductsLabel || 'Select',
                                        })
                                    }
                                >
                                    {data.allProductsLabel || 'Select'}
                                </button>
                            </li>
                        )}
                        {data.collections?.map(
                            ({ _uid, ...collection }) =>
                                collection?.title &&
                                data.products?.[collection?.handle]?.length > 0 && (
                                    <li
                                        key={_uid}
                                        role="option"
                                        aria-selected={selectedValue === collection.handle}
                                    >
                                        <button
                                            className="option px-4 py-1.5 w-full text-left"
                                            onClick={() =>
                                                handleSetSelected({
                                                    value: collection.handle,
                                                    label: collection.title,
                                                })
                                            }
                                            disabled={selectedValue === collection.handle}
                                        >
                                            {collection.title}
                                        </button>
                                    </li>
                                )
                        )}
                    </ul>
                </div>
            </div>

            {data.products?.[selectedValue] && (
                <RevealElement as="div" className="p-0 lg:px-m-lg">
                    <ScrollingCarousel
                        ariaLabel="product"
                        prevNextButtons={true}
                        PreviousButton={PreviousButton}
                        NextButton={NextButton}
                        alignItems="start"
                    >
                        {data.products?.[selectedValue]?.map((product, i) => {
                            return (
                                <ProductCardColor
                                    key={product.handle}
                                    product={product}
                                    useColorBg={selectedValue.toLowerCase() === 'colors'}
                                    position={i + 1}
                                    list={data.list}
                                />
                            );
                        })}
                    </ScrollingCarousel>
                </RevealElement>
            )}
        </Organism>
    );
};

FeaturedCollectionsProducts.propTypes = {
    blok: PropTypes.object,
};

const CardOuter = styled.span` 
    cursor: pointer;
    padding: ${props => props.padding};
    height: 100%;
    display: flex;
    flex-direction: column;
    .card-img-cont { 
        position: relative;
        width: 100%;
        height: 0%;
        padding-bottom: 100%;
        padding-bottom: ${props => props.aspectRatio};
        overflow: hidden;
        ${({ aspectRatioMob }) =>
            aspectRatioMob &&
            `
            @media ${breakpoints.maxXs} {
                padding-bottom: ${aspectRatioMob};
            }
        `}
        img {
            position: absolute;
            transition: all 0.2s linear;
            min-width: 100%;
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            object-position: center;
        }
        &.multiple-images {
            .image-1 {
                opacity: 0;
            }
            @media screen and ${breakpoints.md} {
                &:hover {
                    .image-0 {
                        opacity: 0;
                    }
                    .image-1 {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .card-img-cont--next {
        > div,
            > div > div {
                position: absolute !important;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 0 !important;
            }
        }
    }
`;

const ProductCardColor = ({ product, useColorBg, position, list }) => {
    const {
        store: {
            tradeDiscountValue,
            customer,
            customerLoggedIn,
            checkout: { currencyCode },
        },
        setDataLayerList,
    } = useContext(SiteContext);

    const data = useMemo(() => {
        const template =
            product?.metafields?.find(mf => mf?.key === 'template')?.value || 'default';
        const hasColorBg = useColorBg && !!product.images?.[2];
        const props = {
            imgLoading: 'lazy',
            title: product.title,
            handle: product.handle,
            images: hasColorBg ? [product.images?.[2]] : product.images.slice(0, 2),
            collectionTitle: product.collection.title,
            price: product.price,
            nonTilePDP:
                product.metafields?.find(mf => mf?.key === 'non_tile_pdp')?.value === 'false' ||
                !product.metafields?.find(mf => mf?.key === 'non_tile_pdp')?.value
                    ? false
                    : true,
            template,
            ...(template === 'base-molding' && {
                linearftBoxRatio: product.metafields?.find(
                    mf => mf?.key === 'linear_feet_box_ratio'
                )?.value,
            }),
            ...(template === 'default' && {
                sqftBoxRatio: parseFloat(
                    product.metafields?.find(mf => mf?.key === 'square_feet_box_ratio')?.value
                ),
            }),
            compareAtPrice: product.compareAtPrice,
            productId: product.id,
            variantId: product.selectedOrFirstAvailableVariant.id,
            vendor: product.vendor,
            collections: product.collections,
            variantTitle: product.selectedOrFirstAvailableVariant.title,
            variantSku: product.selectedOrFirstAvailableVariant.sku,
        };
        return {
            ...props,
        };
    }, [product]);

    const {
        title,
        handle,
        images,
        price,
        className,
        imgWidth,
        imgHeight,
        imgPriority,
        imgLoading,
        imgSizes,
        sqftBoxRatio,
        linearftBoxRatio,
        template,
        nonTilePDP,
        compareAtPrice,
        productId,
        variantId,
        vendor,
        collections,
        variantTitle,
        variantSku,
    } = { ...data };

    const pricePerSquareFoot = useMemo(() => {
        if (template === 'default') return roundToTwo(Number(price) / Number(sqftBoxRatio));
    }, [price, sqftBoxRatio]);
    const pricePerLinearFoot = useMemo(() => {
        if (template === 'barliner') return roundToTwo(Number(price) * 2);
        if (template === 'base-molding')
            return roundToTwo(Number(price) / Number(linearftBoxRatio));
    }, [price, linearftBoxRatio]);

    const handleClick = () => {
        setDataLayerList(list);
        /** Collection/Search page product click...
                this is the product the user clicks on from collection page **/
        window.ElevarDataLayer = window.ElevarDataLayer ?? [];
        const compare_at_price = Number(compareAtPrice) || Number(price);
        const sale_price = (Number(price) * (100 - tradeDiscountValue)) / 100;
        const selectItemData = {
            event: 'dl_select_item',
            user_properties: {
                ...transformToElevarUserProperties({ customer, customerLoggedIn }),
            },
            ecommerce: {
                currencyCode: currencyCode,
                click: {
                    actionField: {
                        list, // this should be the collection page URL
                        action: 'click',
                    },
                    products: [
                        {
                            id: variantSku || handle, // SKU
                            name: title, // Product title
                            brand: vendor || 'Zia Tile',
                            category:
                                collections
                                    ?.slice(0, 5)
                                    ?.map(({ title }) => title)
                                    ?.join(',') || '',
                            variant: variantTitle !== 'Default Title' ? variantTitle : '',
                            price: roundToTwo(sale_price),
                            position, // Only required for dl_select_item; position in the list of search results, collection views and position in cart indexed starting at 1,
                            list, // The list the product was discovered from
                            product_id: String(productId).replace('gid://shopify/Product/', ''), // The product_id
                            variant_id: String(variantId).replace(
                                'gid://shopify/ProductVariant/',
                                ''
                            ), // id or variant_id
                            compare_at_price:
                                compare_at_price > sale_price
                                    ? roundToTwo(compare_at_price)
                                    : '0.0', // If available on dl_view_item & dl_add_to_cart otherwise use "0.0"
                            image: (images?.[0]?.src ?? '').replace('https:', ''), // If available, otherwise use an empty string
                            url: `${process.env.NEXT_PUBLIC_SITE_URL}/products/${handle}`, // URL for the Product Page. Only required for dl_add_to_cart.
                        },
                    ],
                },
            },
        };
        window.ElevarDataLayer.push({ ...selectItemData });
        logInfo('Fire Elevar dl_select_item', APPS.FRONTEND, selectItemData);
    };
    return (
        <Link
            href={`/products/${handle}`}
            className="flex-auto flex-shrink-0 flex-grow-0 w-80"
            onClick={handleClick}
        >
            <CardOuter className={`mb-6 ${className}`}>
                <div
                    className={`card-img-cont card-img-cont--next mb-4 ${
                        images.length > 1 ? 'multiple-images' : ''
                    }`}
                >
                    {images.map((image, i) => {
                        return (
                            <NextImage
                                key={i}
                                className={`image-${i}`}
                                src={image.src}
                                alt={`${title} - Featured products homepage featured collections`}
                                width={imgWidth || 320}
                                height={imgHeight || 320}
                                sizes={imgSizes}
                                loading={imgLoading}
                                priority={imgPriority}
                            />
                        );
                    })}
                </div>
                <BodyText
                    as="h3"
                    className="mr-1 text-center mb-2"
                    fontSize="0.875rem"
                    fontWeight="500"
                    lineHeight="1"
                >
                    {title}
                </BodyText>
                {nonTilePDP || title === '511 POROUS PLUS SEALER' ? (
                    <>
                        {tradeDiscountValue && (
                            <BodyText
                                className="text-center mb-2"
                                as="strike"
                                fontSize="0.875rem"
                                lineHeight="1"
                            >
                                ${Number(price).toFixed(2)}
                            </BodyText>
                        )}
                        <BodyText className="text-center mb-2" fontSize="0.875rem" lineHeight="1">
                            ${roundToTwo(Number((price * (100 - tradeDiscountValue)) / 100))}
                        </BodyText>
                    </>
                ) : (
                    <span className="flex flex-wrap items-center justify-center gap-x-1.5">
                        {tradeDiscountValue && (
                            <>
                                {pricePerSquareFoot && !isNaN(pricePerSquareFoot) && (
                                    <BodyText
                                        className="text-center"
                                        as="strike"
                                        fontSize="0.85rem"
                                        lineHeight="1"
                                        color="rgb(var(--subdued-text-color))"
                                    >
                                        ${pricePerSquareFoot} /sqft
                                    </BodyText>
                                )}
                                {pricePerLinearFoot && !isNaN(pricePerLinearFoot) && (
                                    <BodyText
                                        className="text-center"
                                        as="strike"
                                        fontSize="0.85rem"
                                        lineHeight="1"
                                        color="rgb(var(--subdued-text-color))"
                                    >
                                        ${pricePerLinearFoot} /linear ft
                                    </BodyText>
                                )}
                            </>
                        )}
                        {pricePerSquareFoot && !isNaN(pricePerSquareFoot) && (
                            <BodyText className="text-center" fontSize="0.875rem" lineHeight="1">
                                $
                                {roundToTwo(
                                    (pricePerSquareFoot * (100 - tradeDiscountValue)) / 100
                                )}{' '}
                                /ft<sup>2</sup>
                            </BodyText>
                        )}
                        {pricePerLinearFoot && !isNaN(pricePerLinearFoot) && (
                            <BodyText className="text-center" fontSize="0.875rem" lineHeight="1">
                                $
                                {roundToTwo(
                                    (pricePerLinearFoot * (100 - tradeDiscountValue)) / 100
                                )}{' '}
                                /linear ft
                            </BodyText>
                        )}
                    </span>
                )}
            </CardOuter>
        </Link>
    );
};

ProductCardColor.propTypes = {
    product: PropTypes.object,
    useColorBg: PropTypes.bool,
    position: PropTypes.number,
    list: PropTypes.string,
};

const PreviousButton = ({ disabled, ...props }) => (
    <button
        className={`hidden lg:block absolute z-10 top-1/2 left-0 -translate-y-1/2 -translate-x-full ${
            disabled ? 'opacity-50' : ''
        }`}
        {...props}
        disabled={disabled}
    >
        <ChevronLeftIcon />
    </button>
);

PreviousButton.propTypes = {
    disabled: PropTypes.bool,
};

const NextButton = ({ disabled, ...props }) => (
    <button
        className={`hidden lg:block absolute z-10 top-1/2 right-0 -translate-y-1/2  translate-x-full ${
            disabled ? 'opacity-50' : ''
        }`}
        {...props}
        disabled={disabled}
    >
        <ChevronRightIcon />
    </button>
);

NextButton.propTypes = {
    disabled: PropTypes.bool,
};
export default FeaturedCollectionsProducts;
